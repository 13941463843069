<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.interactive.open_card.type') }}
      </div>
      <el-select
        size="mini"
        v-model="localValue.type"
      >
        <el-option
          v-for="item in Object.keys($t('process_editor.element_properties.user_task.interactive.open_card.types'))"
          :key="item"
          :label="$t(`process_editor.element_properties.user_task.interactive.open_card.types.${item}`)"
          :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="property">
      <div class="label">
        {{ $t('main.fields.object_id') }}
      </div>
      <registry-select-tree
        type="registry"
        value-as="number"
        :cache="true"
        :value="parseInt(localValue.registry_id)"
        @change="$set(localValue, 'registry_id', $event)"
      />
    </div>
    <template v-if="localValue.registry_id">
      <div>
        <div class="property">
          <div class="label">
            {{ $t('interface_editor.card') }}
          </div>
          <interface-card
            :object-id="localValue.registry_id"
            :value="parseInt(localValue.card_id) || null"
            @input="$set(localValue, 'card_id', $event)"
          />
        </div>
        <template v-if="localValue.type !== 'add'">
          <div class="property">
            <div class="label">
              {{ $t('process_editor.element_properties.user_task.interactive.open_card.record_id_type_title') }}
            </div>
            <el-select
              size="mini"
              v-model="localValue.record_id_type"
            >
              <el-option
                v-for="item in Object.keys($t('process_editor.element_properties.user_task.interactive.open_card.record_id_types'))"
                :key="item"
                :label="$t(`process_editor.element_properties.user_task.interactive.open_card.record_id_types.${item}`)"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="property" v-if="localValue.record_id_type && localValue.record_id_type !== 'current'">
            <div class="label">
              {{
                $t(`process_editor.element_properties.user_task.interactive.open_card.record_id_types.${localValue.record_id_type}`)
              }}
            </div>
            <template v-if="localValue.record_id_type === 'variable'">
              <el-input
                size="mini"
                :value="localValue.record_id_value"
                @input="$set(localValue, 'record_id_value', $event)"
              />
            </template>
            <template v-else-if="localValue.record_id_type === 'field'">
              <registry-select-tree
                type="field"
                value-as="number"
                :cache="true"
                :parent-id="fieldRegistryId"
                :value="parseInt(localValue.record_id_value)"
                @change="$set(localValue, 'record_id_value', $event)"
              />
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'
import InterfaceCard from '@/core/infrastructure/components/InterfaceCard.vue'
import Template from '@/components/EtlEditor/Properties/Template.vue'

export default {
  name: 'OpenCard',
  components: { Template, InterfaceCard, RegistrySelectTree },
  props: {
    fieldRegistryId: Number,
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    localValue (val) {
      this.$emit('input', val)
    },
    'localValue.record_id_type': {
      handler () {
        this.localValue.record_id_value = null
      }
    },
    'localValue.registry_id': {
      handler () {
        this.$set(this.localValue, 'card_id', null)
        this.$set(this.localValue, 'record_id_type', null)
        this.$set(this.localValue, 'record_id_value', null)
      }
    }
  }
}
</script>
